<!-- Desktop -->
<div class="d-none d-lg-flex card bg-white p-3">
  <div class="row justify-content-center align-items-center">
    <div class="col-3">
      <div class="image">
        <img
          class="w-100"
          [src]="product.image | toThumbnail"
          alt=""
          (error)="onImgError()"
        />
      </div>
    </div>
    <div class="col-5">
      <span class="product-name">{{ product.name }} </span>
      <div class="my-2">
        <div class="product-price mt-3 w-75 p-0">
          <small
            *ngIf="product.price.listPrice > product.price.finalPrice"
            class="mr-2"
            >{{ product.price.listPrice | kaCurrency }}</small
          >
          <span>{{ product.price.finalPrice | kaCurrency }}</span>
        </div>
        <small *ngIf="user.countryId === 'CL'" class="text-secondary p-2">{{
          'NEW_ORDER.ORDER_DETAIL.UNIT'
            | translate: { price: product.price.priceBySubUnit | kaCurrency }
        }}</small>
      </div>
      <p class="suggestion-quantity mid-strong">
        {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate }}
        <span>{{ product.suggestedProduct.quantity }}</span>
        <span> {{ product.unitMeasure | lowercase }}</span>
        <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
      </p>
    </div>
    <div class="col-4">
      <app-ka-add-to-cart-button
        [product]="product"
        [onCart]="true"
        [subUnit]="product.erpUnitMeasureId"
        [stockDigital]="stockDigital"
        (hasAddedProduct)="hasAddedProductHandler($event)"
      ></app-ka-add-to-cart-button>
    </div>
  </div>
</div>

<!-- Mobile -->
<div class="d-flex d-lg-none card">
  <div class="row justify-content-center align-items-center">
    <div class="col-4">
      <div class="row image w-100 p-3">
        <img
          class="w-100"
          [src]="product.image | toThumbnail"
          alt=""
          (error)="onImgError()"
        />
      </div>
    </div>
    <div class="col-8">
      <div class="row product-data px-2">
        <span class="product-name px-4">{{ product.name }}</span>
      </div>
      <div class="row product-data px-4 py-3">
        <div
          class="product-price p-0 pl-2 text-center d-flex flex-row align-items-center justify-content-between"
        >
          <small *ngIf="product.price.listPrice > product.price.finalPrice">{{
            product.price.listPrice | kaCurrency
          }}</small>
          <span>{{ product.price.finalPrice | kaCurrency }}</span>
        </div>
        <small *ngIf="user.countryId === 'CL'" class="text-secondary p-2">{{
          'NEW_ORDER.ORDER_DETAIL.UNIT'
            | translate: { price: product.price.priceBySubUnit | kaCurrency }
        }}</small>
      </div>
      <p class="suggestion-quantity px-2 mid-strong mb-3">
        {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate }}
        <span>{{ product.suggestedProduct.quantity }}</span>
        <span> {{ product.unitMeasure | lowercase }}</span>
        <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
      </p>
      <app-ka-add-to-cart-button
        [product]="product"
        [subUnit]="product.erpMeasureUnitId"
        [stockDigital]="stockDigital"
        (hasAddedProduct)="hasAddedProductHandler($event)"
      ></app-ka-add-to-cart-button>
    </div>
  </div>
</div>
