import { inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { OrderStatus } from 'src/app/core/enums/order-status';
import { Client } from 'src/app/core/models/client.model';
import { OrderDetail } from 'src/app/core/models/order-detail.model';
import { Order } from 'src/app/core/models/order.model';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';
import { StockDigital } from '../../../core/models/stock-digital.model';
import { catchError, map, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { AppUtils } from '../../../core/utils/app-utils';
import { Router } from '@angular/router';
import { CountryCodes } from '../../../core/enums/country-codes.enum';
import { Cart } from '../../../core/models/cart.model';
import { UserInfo } from '../../../core/models/user-info.model';
import { Product } from '../../../core/models/product.model';
import { StockDigitalModalComponent } from '../../new-order/modals/stock-digital-modal/stock-digital-modal.component';
import * as CartActions from '../../../core/state/actions/cart.actions';
import { bulkDeleteAnyProducts } from '../../../core/state/actions/cart.actions';
import { CartService } from '../../../core/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SourceChannels } from 'src/app/core/enums/source-channels';

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements OnDestroy {
  client: Client;
  cart: Cart;
  user: UserInfo;
  private modalService = inject(NgbModal);
  private cartService = inject(CartService);

  private subscriptions = new Subscription();
  readonly EndpointsCodes = EndpointsCodes;

  constructor(
    private apiSrv: ApiService,
    private store: Store<{ client: Client; cart: Cart; user: UserInfo }>,
    private router: Router,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  getClientLastOrder(): Observable<Order> {
    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/lastorder`,
          EndpointsCodes.GET_LAST_ORDER,
          { showError: false },
        )
        .subscribe(
          (res) => {
            obs.next(res.data);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getClientOrders(): Observable<Order[]> {
    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/order`,
          EndpointsCodes.GET_ORDERS_HISTORY,
          { showError: false },
        )
        .subscribe(
          (res) => {
            const ordersFilteredByEnv = res.data.filter(
              (order) =>
                order.orderId?.startsWith(
                  environment.ORDER_LIST_INIT_PATTERN,
                ) || order.orderId === null,
            );
            const ordersGrouped = AppUtils.groupBy(
              ordersFilteredByEnv,
              'orderId',
            );
            const ordersParsed = [];

            AppUtils.mapValues(ordersGrouped, (orders) => {
              if (orders.length === 1) {
                //push single orders
                ordersParsed.push(orders[0]);
              } else if (orders.every((order) => !order.orderId)) {
                //push orders without orderId
                ordersParsed.push(...orders);
              } else {
                // array of orders
                const b2bOrder = orders.find((order) => order.origin === 'B2B');
                if (b2bOrder) {
                  const sapOrders = orders.filter(
                    (order) => order.origin !== 'B2B',
                  );
                  b2bOrder.sapOrders =
                    sapOrders.length > 0 ? [...sapOrders] : undefined;
                  if (b2bOrder.status === OrderStatus.CANCELLATION_REQUEST) {
                    b2bOrder.sapOrders?.map((sapOrder) => {
                      if (
                        [
                          OrderStatus.REGISTERED,
                          OrderStatus.DELIVERED,
                          OrderStatus.BLOCKED,
                        ].includes(sapOrder.status)
                      ) {
                        sapOrder.status = OrderStatus.CANCELLATION_REQUEST;
                      }
                      return sapOrder;
                    });
                  }
                  ordersParsed.push(b2bOrder);
                } else {
                  ordersParsed.push(...orders);
                }
              }
            });
            const orderedOrders = AppUtils.orderBy(
              ordersParsed,
              [(order) => order.orderDate],
              ['desc'],
            );

            orderedOrders.forEach((order) => {
              if (!order.sapOrders) order.sapOrders = [{ ...order }]; //TODO remove later. TEMPORAL CHANGE
            });
            obs.next(orderedOrders);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getOrderDetail(orderId): Observable<OrderDetail> {
    const isExternal = this.router.url.includes('isExternal') ? true : '';
    const queryParams = this.apiSrv.generateQueryParams({ isExternal });
    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/order/${orderId}${queryParams}`,
          EndpointsCodes.GET_ORDER_DETAIL,
          { showError: true },
        )
        .subscribe(
          (res) => {
            res.data = { ...res?.data, orderId };
            res.data?.products.forEach((product) => {
              const listPrice = parseFloat(product?.totals?.listPrice || 0);
              const shipping = parseFloat(product?.totals?.shippingPrice || 0);
              product.totals.listPrice = listPrice + shipping;
            });
            obs.next(res.data);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getOrderInvoice(orderId: number, erpOrderId: string): Observable<any> {
    return new Observable((obs) => {
      return this.apiSrv
        .get(
          `clients/${this.client.clientId}/order/${orderId}/invoice/${erpOrderId}`,
          EndpointsCodes.GET_INVOICE_ORDER,
          { showError: true },
        )
        .subscribe(
          (res) => {
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  cancelOrder(orderId: number): Observable<any> {
    return new Observable((obs) => {
      this.apiSrv
        .post(
          `clients/${this.client.clientId}/order/${orderId}/cancellation_order`,
          EndpointsCodes.POST_CANCEL_ORDER,
          {},
          { showError: true },
        )
        .subscribe(
          (res) => {
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getIconName(status) {
    switch (status) {
      case OrderStatus.created:
      case OrderStatus.DELIVERED:
      case OrderStatus.FAILED:
      case OrderStatus.REGISTERED:
        return 'entered';
      case OrderStatus.PREPARING:
        return 'preparing';
      case OrderStatus.TRANSIT:
        return 'transit';
      case OrderStatus.CANCELLATION_REQUEST:
        return 'cancelling';
      case OrderStatus.ORDER_NOT_PROCESSED:
      case OrderStatus.CANCELLED:
      case OrderStatus.BLOCKED:
        return 'cancelled';
      case OrderStatus.RETURNED:
        return 'returned';
      case OrderStatus.DELIVERED_CLT:
      case OrderStatus.PARTIAL_DELIVERY:
        return 'delivered';
      default:
        return 'cancelling';
    }
  }

  checkStockDigital(ids: string): Observable<StockDigital[]> {
    return this.apiSrv
      .get(
        `clients/${this.client.clientId}/stockdigital?items=${ids}`,
        EndpointsCodes.STOCK_DIGITAL,
        {
          showError: false,
          customSpinner: true,
          customSpinnerMsg: 'LOADINGS.GET_STOCK',
        },
      )
      .pipe(
        catchError(() => {
          return of({
            body: {},
          });
        }),
        map(({ body }) => body ?? {}),
        shareReplay(1),
      );
  }

  stockDigitalHandler(redirectFn: any) {
    moment.locale('en');
    // 0 = Sunday
    // 6 == Saturday

    let invoiceDeadline = moment(
      typeof this.cart.invoiceDeadline === 'string'
        ? this.cart.invoiceDeadline
        : (this.cart.invoiceDeadline as Date),
    ).add(2, 'days');

    invoiceDeadline =
      invoiceDeadline.weekday() === 0
        ? moment(invoiceDeadline).add(1, 'days')
        : invoiceDeadline;

    const deadlineEpoch = invoiceDeadline.startOf('day').valueOf();
    const isVisitDateInRange =
      deadlineEpoch >= moment(this.cart.visitDate).startOf('day').valueOf();
    const isFrozenVisitDateInRange =
      this.cart.frozenVisitDate &&
      deadlineEpoch >=
        moment(this.cart.frozenVisitDate).startOf('day').valueOf();

    if (
      this.user?.countryId === CountryCodes.CHILE &&
      (isVisitDateInRange || isFrozenVisitDateInRange)
    ) {
      const ids = [];

      if (isFrozenVisitDateInRange) {
        ids.push(
          this.cart.discountProducts
            .filter((item) => item.deliveryType === 'deliveryfrozen')
            .map(({ erpProductId }) => erpProductId)
            .join(),
        );
      }

      if (isVisitDateInRange) {
        ids.push(
          this.cart.discountProducts
            .filter((item) => item.deliveryType === 'delivery')
            .map(({ erpProductId }) => erpProductId)
            .join(),
        );
      }

      this.checkStockDigital(ids.join()).subscribe({
        next: (skus) => {
          const foundStock = [];

          Object.keys(skus).forEach((id) => {
            if (!skus[id]) {
              const productFoundInList = this.cart.discountProducts.find(
                (p) => p.erpProductId === id || p.erpProductId.includes(id),
              );
              foundStock.push(productFoundInList);
            }
          });

          if (foundStock.length) {
            this.showStockDigitalModal(foundStock, redirectFn);
          } else {
            redirectFn();
          }
        },
        error: (err) => {
          console.error('StockDigital Error: ', err);
          redirectFn();
        },
      });
    } else {
      redirectFn();
    }
  }

  showStockDigitalModal(products: Product[], redirectFn: any) {
    const modalConfirm = this.modalService.open(StockDigitalModalComponent, {
      windowClass: 'ngbmodal-centered stock-digital-modal',
    });
    modalConfirm.componentInstance.data = {
      products,
      itemColor: 'text-warning',
    };

    modalConfirm.result.then(
      () => {
        //noop
      },
      (confirm) => {
        this.store.dispatch(bulkDeleteAnyProducts({ products }));
        products.forEach((product) =>
          this.store.dispatch(CartActions.deleteProduct({ product })),
        );
        this.cartService.updateDeliveryProducts();

        if (confirm) {
          redirectFn();
        }
      },
    );
  }

  isStockDigitalAvailable(): Observable<any> {
    return this.apiSrv.get(
      `clients/${this.client.clientId}/allowstockdigital?erpClientId=${this.client.erpClientId}`,
      EndpointsCodes.STOCK_DIGITAL,
      {
        showError: true,
      },
    );
  }

  getDeliveryDate(order, hasSingleDelivery) {
    return hasSingleDelivery
      ? moment(order.deliveryDates[0].deliveryDate).format('DD/MM/YYYY')
      : order.deliveryDate;
  }

  isOrderCreatedFromMET(order: Order | OrderDetail): boolean {
    return order?.sourceChannel === SourceChannels.LOY ||
      order?.sourceChannel === SourceChannels.PEDIDO_SIN_CARGO;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
