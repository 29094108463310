import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ProductTypes } from '../enums/product-types';
import { Client } from '../models/client.model';
import { HomeOptions } from '../models/home-option.model';
import { UserInfo } from '../models/user-info.model';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { BERespModel } from '../models/backend/BE-response.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private subscriptions = new Subscription();
  selectedClient: Client;
  user: UserInfo;
  homeOptions: HomeOptions[];

  constructor(private store: Store<{ client: Client, user: UserInfo }>, private apiSrv: ApiService) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      })
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      })
    );
  }

  getHomeOption(): HomeOptions[] {
    this.homeOptions = [
      {
        id: '1',
        title: 'HOME.MY_ORDERS_DESKTOP',
        icon: 'my-orders',
        routerLink: this.user.uuid ? '/mcc/mis-pedidos/' : '/main/mis-pedidos/',
        show: !!this.selectedClient.data.orders,
      },
      {
        id: '2',
        title: 'NEW_ORDER.SUGGESTED_PRODUCTS.PRODUCTS',
        icon: 'catalog',
        routerLink: this.user.uuid ? '/mcc/2/' : '/main/nuevo-pedido/2/',
        routerLinkQuery: { grupo: ProductTypes.UNIT },
        show: !this.selectedClient.hasLockOrder && !!this.selectedClient.data.visitDates.length && !this.selectedClient.data?.hasCreditLock && !this.selectedClient.lockOrderMissingPayment,
      },
    ];
    return this.homeOptions;
  }

  getBannerImages(): Observable<BERespModel> {
    return new Observable((obs) => {
      this.apiSrv.get(`clients/${this.selectedClient.clientId}/banners`, EndpointsCodes.GET_BANNERS, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}