import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { pairwise, take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Client } from 'src/app/core/models/client.model';
import { OptionsMet } from 'src/app/core/models/met/met.model';
import { FEATURES } from 'src/environments/utils/env.model';
import { UserInfo } from 'src/app/core/models/user-info.model';

import { env } from 'src/app/app.component';

import { NavbarMenuMobileService } from '../../ka-navbar-menu-mobile/services/navbar-menu-mobile.service';

import { getOptionsMet, removeMetUrl, saveMetUrl } from 'src/app/core/state/actions/ui.actions';
import { selectFeatureOptionsMet } from 'src/app/core/state/reducers/ui.reducer';

@Injectable({
  providedIn: 'root'
})
export class SidebarMetService {

  private options: OptionsMet[];
  subscription = new Subscription;
  user: UserInfo;

  get client$(): Observable<Client> {
    return this.store.select('client');
  }

  get optionsMet$(): Observable<OptionsMet[]> {
    return this.store.select(selectFeatureOptionsMet);
  }

  constructor(
    private store: Store<{ client: Client, user: UserInfo }>,
    private router: Router,
    private navbarMenuMobileService: NavbarMenuMobileService
  ) { 
    this.store.select('user').subscribe((user) => (this.user = user));
  }

  initMetLogic(): void {
    if (this.isFeatureAvailable()) {
      this.getOptionsMet();
    }
  }

  private isFeatureAvailable(): boolean {
    return env.isFeatureAvailable(FEATURES.REWARDS);
  }

  private getOptionsMet(): void {
    this.subscription.add(
      this.client$.pipe(take(1)).subscribe({
        next: (client) => {
          this.store.dispatch(
            getOptionsMet({ clientId: client.clientId }),
          );
          this.newClientSelected();
        },
      }),
    );
  }

  private newClientSelected(): void {
    this.subscription.add(
      this.client$.pipe(pairwise()).subscribe({
        next: ([previousClient, currentClient]) => {
          if (previousClient.clientId !== currentClient.clientId) {
            this.store.dispatch(
              getOptionsMet({ clientId: currentClient.clientId }),
            );
          }
        }
      })
    );
  }

  setOptionsMetUp(menuItems: any) {
    this.optionsMet$.subscribe({
      next: (options) => {
        this.options = options;
        menuItems.forEach((item) => {
          if (item.langKey === 'MY_REWARDS') {
            if (this.showItemMenuRewards()) {
              item.show = true;
              item.options = this.options;
              this.setupOptionDisplay();
              return;
            }
            item.show = false;                    
          }          
        });
      }
    });
  }

  showItemMenuDigitalPromotions(menuItems: any){
    this.optionsMet$.subscribe({
      next: (options) => {
        this.options = options;        
        menuItems.forEach((item) => {
          if (item.langKey === 'DIGITAL_PROMOTIONS') {                      
            if(this.user.countryId === "PY"){
              item.show = this.showItemMenuRewards();      
            }                   
          }  
        });
      }
    });
  }

  private showItemMenuRewards(): boolean {
    return this.options.length !== 0;
  }

  private setupOptionDisplay(): void {
    const optionMapping = {
      award_wallet: { label: 'Mis Puntos', icon: 'tabler-award-wallet' },
      bingo: { label: 'Mi Bingo', icon: 'tabler-bingo' },
      gana_tool: { label: 'Mis Objetivos', icon: 'tabler-target-arrow' },
      foto_tool: { label: 'Mis Fotos', icon: 'tabler-camera' },
    };
  
    this.options.forEach((option) => {
      const mapping = optionMapping[option.code];
      if (mapping) {
        option.label = mapping.label;
        option.icon = mapping.icon;
      }
    });
  }
  
  saveURLintoStore(url: string): void {
    this.store.dispatch(saveMetUrl({ url }));
    this.navbarMenuMobileService.openCloseNavBarMenuMobile();
    this.router.navigate(['/main/rewards']);
  }

  removeUrlOfStore(): void {
    this.store.dispatch(removeMetUrl());
  }
}
