import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UserInfo } from 'src/app/core/models/user-info.model';
import { UserLocal } from 'src/app/core/models/user-local.model';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/core/services/home.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-ka-carousel',
  templateUrl: './ka-carousel.component.html',
  styleUrls: ['./ka-carousel.component.scss'],
  providers: [NgbCarouselConfig],
})
export class KaCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  private subscriptions = new Subscription();
  countrySelected: any;
  bannerImages: string[];
  user: UserInfo;
  bannersUrl = environment.WEB_DOMAIN + 'images/banners';

  constructor(
    private store: Store<{ userLocal: UserLocal; user: UserInfo }>,
    public config: NgbCarouselConfig,
    private homeService: HomeService,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {
    this.configCarousel();
    this.subscriptions.add(
      this.store.select('user').pipe(
        filter(user => !!user && !!user?.jwt),
        first(),
      )
      .subscribe((user) => {
        this.user = user;
        this.getBannerImages();
      }),
    );
  }

  ngOnInit(): void {
    this.carousel?.cycle();
  }

  configCarousel() {
    this.config.interval = 10000;
    this.config.wrap = true;
    this.config.keyboard = true;
    this.config.pauseOnHover = true;
    this.config.showNavigationArrows = false;
    this.config.showNavigationIndicators = false;
  }

  getBannerImages():void {
    this.homeService.getBannerImages().subscribe(
      (res) => {
        this.bannerImages = res.data;
        this.controlNavigation();
      },
      (error) => {
        const errorMessage = this.translateService.instant('HOME.BANNERS.ERROR_LOAD_BANNER');
        this.toastr.error(errorMessage);
      }
    );
  }

  controlNavigation() {
    const showNavigationControls = this.bannerImages.length > 1;
    this.config.showNavigationIndicators = showNavigationControls;
    this.config.showNavigationArrows = showNavigationControls;
  }         

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}