import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';

@Component({
  selector: 'app-ka-suggested-button',
  templateUrl: './ka-suggested-button.component.html',
  styleUrls: ['./ka-suggested-button.component.scss'],
})
export class KaSuggestedButtonComponent implements OnInit, OnDestroy {
  selectedClient: Client;
  private subscriptions = new Subscription();
  ROOT_LANG = 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_BUTTON.';
  constructor(private store: Store<{ client: Client }>) {
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.selectedClient = client)));
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
