<div [ngClass]="{ minimalist: minimalist }" class="select-commerce d-flex flex-column justify-content-between align-items-start">
  <div class="select-group">
    <p *ngIf="!minimalist" class="label font-weight-bold">{{ 'HOME.MY_STORES_SELECT' | translate }}</p>

    <p *ngIf="(selectedClient && clients?.length === 1) || !hasAllowedRole" class="selected-client">{{ selectedClient.street }} {{ selectedClient.doorNumber }}</p>

    <div class="px-0 w-100" *ngIf="selectedClient && clients?.length > 1" ngbDropdown class="d-inline-block">
      <button type="button" class="btn px-0 py-0 w-100 d-flex flex-row align-items-center justify-content-between"id="dropdownClients" ngbDropdownToggle>
        <span class="selected-client">{{ selectedClient.street }} {{ selectedClient.doorNumber }}</span>
        <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-down'" /></svg>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownClients">
        <ng-container *ngFor="let client of clients">
          <button *ngIf="client.clientId !== selectedClient.clientId" ngbDropdownItem (click)="selectClient(client)">
            {{ client.street }} {{ client.doorNumber }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
