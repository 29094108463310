import { createReducer, createSelector, on } from '@ngrx/store';
import * as UiActions from '../actions/ui.actions'
import { OptionsMet } from '../../models/met/met.model';
import { AppState } from '../app.state';

export interface UiState {
  met: {
    options: OptionsMet[],
    url: string,
  },
}

export const selectFeature = (state: AppState) => state.ui;

export const selectFeatureOptionsMet = createSelector(
  selectFeature,
  (state: UiState) => state.met.options,
);
export const selectFeatureUrlMet = createSelector(
  selectFeature,
  (state: UiState) => state.met.url,
);

export const initialState = {
  met: {
    options: [],
    url: ''
  }
};
export const uiReducer = createReducer<UiState>(
  initialState,
  on(UiActions.successGetOptionsMet, (state, props) => {
    return {
      ...state,
      met: {
        ...state.met,
        options: [...props.options]
      }
    };
  }),
  on(UiActions.failureGetOptionsMet, (state) => {
    return {
      ...state,
      met: {
        ...state.met,
        options: []
      }
    };
  }),
  on(UiActions.resetOptionsMet, (state) => {
    return {
      ...state,
      met: {
        ...state.met,
        options: []
      }
    };
  }),
  on(UiActions.saveMetUrl, (state, props) => {
    return {
      ...state,
      met: {
        ...state.met,
        url: props.url
      }
    };
  }),
  on(UiActions.removeMetUrl, (state) => {
    return {
      ...state,
      met: {
        ...state.met,
        url: ''
      }
    };
  }),
);