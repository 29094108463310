<div class="modal-header">
  <div class="row w-100 pb-4">
    <div class="col-4 col-sm-3">
      <img [src]="discount.image | toThumbnail" class="img-fluid" alt="" />
    </div>
    <div
      class="col-8 col-sm-9 d-flex flex-column align-items-start justify-content-around"
    >
      <app-ka-status-pill
        [status]="pillMsg"
        [isDiscount]="true"
      ></app-ka-status-pill>

      <p class="discount-name">{{ discount.name }}</p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed < 1000 &&
          discount.calculatedCap < 1000 &&
          discount.config.reward.restrictionType === 'physicalBox'
        "
      >
        {{
          'NEW_ORDER.DISCOUNTS.OPEN.MAXIMUM_NUMBER_BOXES_PER_PROMOTION'
            | translate
        }}
        {{ discount.calculatedCap }}
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.BOX' | translate
        }}<span *ngIf="discount.calculatedCap > 1">s</span>
      </p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed > 999 || discount.calculatedCap > 999
        "
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.NO_LIMIT' | translate }}
      </p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed < 1000 &&
          discount.calculatedCap < 1000 &&
          discount.config.reward.restrictionType !== 'physicalBox'
        "
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.DISCOUNT_VALIDTO' | translate
        }}{{ discount.calculatedCap | kaCurrency }}
      </p>
    </div>
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ngb-accordion [closeOthers]="true" activeIds="discount-detail">
    <ng-container>
      <ngb-panel id="discount-detail">
        <ng-template ngbPanelHeader let-opened="opened">
          <button class="border-0 bg-white w-100" ngbPanelToggle>
            <div
              class="d-flex w-100 flex-row align-items-center justify-content-between"
              [class.collapsed]="!opened"
            >
              <div class="group-title">
                <p class="mid-strong">
                  {{ ROOT_LANG + 'DISCOUNTS_DETAIL.TITLE' | translate }}
                </p>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-between"
              >
                <p *ngIf="opened" class="ml-3 ml-md-5">
                  <svg>
                    <use
                      [attr.xlink:href]="
                        './assets/icons/tabler-sprite.svg#tabler-chevron-up'
                      "
                    />
                  </svg>
                </p>
                <p *ngIf="!opened" class="ml-3 ml-md-5">
                  <svg>
                    <use
                      [attr.xlink:href]="
                        './assets/icons/tabler-sprite.svg#tabler-chevron-down'
                      "
                    />
                  </svg>
                </p>
              </div>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div
            *ngFor="let scale of discount.requirements?.scales; let i = index"
          >
            <div
              class="d-flex flex-row align-items-center justify-content-between px-3 py-4 border-bottom"
              *ngIf="scale.reward.value && discount.discountType !== DiscountTypes.SCALE_AMOUNT"
            >
              <p *ngIf="scale.max < 999">
                {{
                  ROOT_LANG + 'DISCOUNTS_DETAIL.SCALE_LABEL'
                    | translate: { scale: scale }
                }}
              </p>
              <p *ngIf="scale.max > 999">
                {{
                  ROOT_LANG + 'DISCOUNTS_DETAIL.SCALE_UNLIMITED_LABEL'
                    | translate: { scale: scale }
                }}
              </p>
              <p
                *ngIf="
                  discount.calculationType === DiscountCalculationType.PERCENT
                "
              >
                <span class="mid-strong">{{ scale.reward.value }}%</span>
                {{ ROOT_LANG + 'DISCOUNTS_DETAIL.DISCOUNT_LABEL' | translate }}
              </p>
              <p
                *ngIf="
                  discount.calculationType === DiscountCalculationType.FIXED
                "
              >
                <span class="mid-strong">{{
                  scale.products[0].price.finalPrice | kaCurrency
                }}</span>
              </p>
            </div>
            <!-- SCALE AMOUT -->
            <div
            class="d-flex flex-row align-items-center justify-content-between px-3 py-4 border-bottom"
            *ngIf="scale.reward.value && discount.discountType === DiscountTypes.SCALE_AMOUNT"
          >
            <p *ngIf="scale.max < 999">
              {{
                ROOT_LANG + 'DISCOUNTS_DETAIL.SCALE_LABEL'
                  | translate: { scale: scale }
              }}
            </p>
            <p *ngIf="scale.max > 999">
              {{
                ROOT_LANG + 'DISCOUNTS_DETAIL.SCALE_UNLIMITED_LABEL_SA'
                  | translate: { scale: scale }
              }}
            </p>
            <p
              *ngIf="
                discount.calculationType === DiscountCalculationType.PERCENT
              "
            >
              <span class="mid-strong">{{ scale.reward.value | toPercent}}%</span>
              {{ ROOT_LANG + 'DISCOUNTS_DETAIL.DISCOUNT_LABEL' | translate }}
            </p>
            <p
              *ngIf="
                discount.calculationType === DiscountCalculationType.FIXED
              "
            >
              <span class="mid-strong">{{
                scale.products[0].price.finalPrice | kaCurrency
              }}</span>
            </p>
          </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
  <div class="mb-3"></div>
  <ngb-accordion [closeOthers]="true" activeIds="group-0">
    <ng-container>
      <ngb-panel id="group-0">
        <ng-template ngbPanelHeader let-opened="opened">
          <button class="border-0 bg-white w-100" ngbPanelToggle>
            <div
              class="d-flex w-100 flex-row align-items-center justify-content-between"
              [class.collapsed]="!opened"
            >
              <div class="group-title">
                <p class="mid-strong">
                  {{ ROOT_LANG + 'PRODUCTS_TITLE' | translate }}
                </p>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-between"
              >
                <p *ngIf="opened" class="ml-3 ml-md-5">
                  <svg>
                    <use
                      [attr.xlink:href]="
                        './assets/icons/tabler-sprite.svg#tabler-chevron-up'
                      "
                    />
                  </svg>
                </p>
                <p *ngIf="!opened" class="ml-3 ml-md-5">
                  <svg>
                    <use
                      [attr.xlink:href]="
                        './assets/icons/tabler-sprite.svg#tabler-chevron-down'
                      "
                    />
                  </svg>
                </p>
              </div>
            </div>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="products-container">
            <div *ngFor="let product of currentScale.products">
              <div
                class="product my-4 d-flex justify-content-between align-items-center"
              >
                <div class="product-image">
                  <img
                    [src]="img_root + product.image | toThumbnail"
                    class="img-fluid"
                    alt=""
                    (error)="onImgError($event)"
                  />
                </div>
                <div class="product-name w-50">
                  <p>{{ product.name }}</p>
                </div>
                <div class="d-flex flex-column price">
                  <div class="product-prices">
                    <small
                      *ngIf="
                        product.price.finalPriceWithoutDiscount !==
                        product.price.finalPrice
                      "
                      class="mx-2"
                      >{{
                        product.price.finalPriceWithoutDiscount | kaCurrency
                      }}</small
                    >
                    <span class="mx-2">{{
                      product.price.finalPrice | kaCurrency
                    }}</span>
                  </div>
                  <small class="text-secondary">{{
                    ROOT_LANG + 'UNIT'
                      | translate
                        : { price: product.price.priceBySubUnit | kaCurrency }
                  }}</small>
                </div>

                <div class="product-pill">
                  <div
                    class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-100 bg-white text-center"
                  >
                    <span
                      (click)="removeProduct(product)"
                      class="button d-flex align-items-center justify-content-center"
                      [ngClass]="{ disabled: product.quantity === 0 }"
                    >
                      <fa-icon icon="minus"></fa-icon>
                    </span>
                    <input
                      type="number"
                      [ngModel]="product.quantity"
                      disabled
                    />
                    <span
                      (click)="addProduct(product)"
                      class="button d-flex align-items-center justify-content-center"
                    >
                      <fa-icon icon="plus"></fa-icon>
                    </span>
                  </div>
                  <!-- <fa-icon icon="minus" (click)="removeProduct(product)"></fa-icon>
                      <span class="mx-3 font-weight-bold text-center">{{ product.quantity }}</span>
                      <fa-icon icon="plus" (click)="addProduct(product)"></fa-icon> -->
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</div>
<div class="modal-footer d-flex justify-content-center">
  <div class="total-prices d-flex justify-content-between w-100">
    <div>
      <p class="total-text">{{ ROOT_LANG + 'TOTAL_LABEL' | translate }}</p>
    </div>
    <div>
      <small class="mr-4" *ngIf="totalListPrice !== totalFinalPrice">{{
        totalListPrice | kaCurrency
      }}</small>
      <span>{{ totalFinalPrice | kaCurrency }}</span>
    </div>
  </div>
  <div class="discount-label-cont d-flex align-items-center w-100 my-2">
    <div class="discount-label">
      <p>
        {{
          ROOT_LANG + '' + currentScaleLabel
            | translate
              : {
                  scale: !!currentScale.reward.value
                    ? currentScale
                    : isCurrentScaleLast
                      ? prevScale
                      : nextScale
                }
        }}
      </p>
    </div>
  </div>
  <button
    type="button"
    class="w-50 btn-square-black mt-4"
    id="add-products-modal"
    [disabled]="!discount.quantitySelected"
    (click)="addProductsToCart()"
  >
    {{ ROOT_LANG + 'ADD_BUTTON' | translate }}
  </button>
</div>
