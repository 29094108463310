<div class="box" *ngIf="discounts && discounts?.length > 0">
  <div class="suggested_box">
    <h3 class="suggested_title">
      <img src="../../../../assets/icons/percent-icon.svg" alt="" srcset="" />
      {{ ROOT_LANG + '.TITLE' | translate }}
    </h3>
  </div>
  <p class="suggested_button" (click)="redirect(2)">
    {{ ROOT_LANG + '.BUTTON' | translate }}
  </p>
</div>
<app-ka-draggable-carousel
  [suggested]="false"
  [whitespace]="false"
  [hideButton]="true"
  *ngIf="discounts && discounts?.length > 0"
>
  <ng-container *ngFor="let item of discounts; index as i">
    <app-discount-product-card
      *ngIf="item.discountId"
      [style]="'GRID'"
      [discount]="item"
      class="pointer ml-4 discount"
      [carousel]="true"
    >
    </app-discount-product-card>
    <app-ka-suggested-product
      *ngIf="!item.discountId"
      [product]="item"
      [page]="'home'"
      class="pointer ml-4 suggested"
      [carousel]="true"
    >
    </app-ka-suggested-product>
  </ng-container>
</app-ka-draggable-carousel>
