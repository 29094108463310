import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'


@Component({
  selector: 'app-product-image-modal',
  templateUrl: './product-image-modal.component.html',
  styleUrls: ['./product-image-modal.component.scss']
})
export class ProductImageModalComponent {
  @Input() imageUrl: string;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  confirm(): void {
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }
}