<button  
  class="quickbutton"
  (click)="navigateTo(quickAccess.addresNavigator)"
   appBestOption
   [isBestOption]="quickAccess.isBestOption" >
    <svg class="img">
        <use attr.xlink:href="{{quickAccess.image }}" />
      </svg>
      <span>
        {{ quickAccess.title | translate }}
      </span>
</button>
