<div class="w-100 red-layer pt-0 pt-lg-3 pr-5 pr-lg-0 pb-lg-5" [ngClass]="{ 'pt-lg-5': user.uuid }">
  <app-ka-navbar class="w-100" *ngIf="!user.uuid && !(user.authValue && user.origin === ExternalIntegration.KOBOSS)"></app-ka-navbar>

    <!-- HOME -->
    <div *ngIf="router.url.split('/')[2] === 'home' || user.uuid" class="header-home pl-5 pb-0 pb-lg-3">
      <p class="hello mb-3 pt-3 pt-lg-0 text-light">{{ 'HOME.HELLO' | translate: { user: user.firstName} }}</p>
      <p class="hello-address mb-3 text-light">{{ 'HOME.CLIENT_NUMBER' | translate: { clientNumber: client.erpClientId } }}</p>
      <p class="hello-address pb-5 pb-lg-0 text-light">{{ 'HOME.CLIENT_SELECTED' | translate }} <span>{{ client.street }} {{ client.doorNumber }}</span></p>
    </div>

    <div *ngIf="router.url.split('/')[2] !== 'home' && !user.uuid" class="pl-5 pt-5 pb-3 pt-lg-0" [ngClass]="{ 'pt-lg-3': user.uuid }">
      <!-- Mis Promociones -->
      <div *ngIf="router.url.split('/')[2] === 'mis-promociones'">
        <p class="title mb-3 pt-2">{{ ROOT_LANG_BENEFITS + 'TITLE' | translate }}</p>
        <p class="subtitle mb-2">{{ ROOT_LANG_BENEFITS + 'SUBTITLE' | translate }}</p>
      </div>
      
      <!-- Otras pantallas -->
      <p *ngIf="router.url.split('/')[2] !== 'mis-promociones'" class="title mb-3 text-light">{{ 'HOME.CLIENT_SELECTED' | translate }}</p>

      <div class="w-100 mb-4 pr-5">
        <p *ngIf="!hasAllowedRole || user?.clients?.length === 1" class="subtitle mid-strong">{{ client.street }} {{ client.doorNumber }}</p>
        <app-ka-select-commerce *ngIf="hasAllowedRole && user?.clients?.length > 1" [minimalist]="true"></app-ka-select-commerce>
      </div>
  </div>
</div>
