import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';

@Component({
  selector: 'app-ka-header-select-client',
  templateUrl: './ka-header-select-client.component.html',
  styleUrls: ['./ka-header-select-client.component.scss'],
})
export class KaHeaderSelectClientComponent implements OnInit {
  user: UserInfo;
  client: Client;
  hasAllowedRole = false;
  readonly ExternalIntegration = ExternalIntegration;
  readonly ROOT_LANG_BENEFITS = 'MY_BENEFITS.HOME.';

  private subscriptions = new Subscription();

  constructor(
    public router: Router,
    private store: Store<{ client: Client; user: UserInfo }>,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
  }

  ngOnInit() {
    this.hasAllowedRole = this.user.role !== 'Buyer';
  }
}
