<div class="badge-pill d-inline-flex flex-row align-items-center" [ngClass]="getStatusClass()">
  <span *ngIf="isDiscount" class="mr-2">
    <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-discount-2'" /></svg>
  </span>
  <ng-container *ngIf="status === DISCOUNT_TEXT">
    <span>{{ DISCOUNT_TEXT | translate }}</span>
  </ng-container>
  <ng-container *ngIf="status ===  BOM">
    <span>{{ BOM | translate }}</span>
  </ng-container>
  <ng-container *ngIf="status !== DISCOUNT_TEXT && status !== BOM">
    <span>{{ ROOT_LANG + status | translate }}</span>
  </ng-container>
</div>
