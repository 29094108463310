<div class="navbar-container" #MobileMenu>
  <div class="d-flex flex-row align-items-center justify-content-start pt-4">
    <div class="image">
      <img
        class="rounded-circle img-fluid w-100 p-3"
        src="./assets/media/comercio.png"
        alt=""
      />
    </div>
    <div class="user-info ps-5">
      <h2>{{ user.firstName }} {{ user.lastName }}</h2>
      <p class="role">
        {{ 'HOME.CLIENT.ROL.' + user.role | translate: { Default: user.role } }}
      </p>
      <a routerLink="/main/mi-perfil">Ver mi perfil</a>
    </div>
  </div>
  <ul class="options">
    <app-ka-menu-items></app-ka-menu-items>
  </ul>
  <ul class="">
    <li *ngIf="!isNewOrder">
      <div class="select-commerce">
        <app-ka-select-commerce
          [hasAllowedRole]="hasAllowedRole"
        ></app-ka-select-commerce>
      </div>
    </li>
    <li *ngIf="!isNewOrder">
      <div class="select-commerce">
        <app-show-delivery-date></app-show-delivery-date>
      </div>
    </li>
    <li class="privacy-terms" (click)="openTyCModal()">
      {{ 'LOGIN.HOME.LEGALS' | translate }}
    </li>
    <li (click)="signOut()" class="pointer" id="sign-out-mobile">
      <svg>
        <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-logout" />
      </svg>
      {{ 'HOME.SIGN_OUT' | translate }}
    </li>
    <li>
      <div class="w-100 pr-3">
        <app-ka-frontend-version></app-ka-frontend-version>
      </div>
    </li>
  </ul>
</div>
