import { Pipe, PipeTransform } from '@angular/core';
import { DefaultImages } from '../../core/enums/default-images';

@Pipe({
  name: 'toThumbnail'
})
export class ToThumbnailPipe implements PipeTransform {

  transform(src: string): string {
    if (src.includes(DefaultImages.DISCOUNT) || src.includes(DefaultImages.PRODUCT)) {
      return src;
    }

    return `${src.substring(0, src.lastIndexOf('.'))}_thumbnail.webp`;
  }

}
