import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ka-home-card',
  templateUrl: './ka-home-card.component.html',
  styleUrls: ['./ka-home-card.component.scss'],
})
export class KaHomeCardComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() routerLink: string;
  @Input() show?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
