<div *ngIf="!readonly" class="dropdown">
  <div
    class="nav-link dropdown-toggle menu-filter-clicked"
    id="dropdown05"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <img
      class="img-fluid flags rounded-circle"
      alt="Country flag"
      src="assets/media/countriesFlags/{{ selectedCountry.key }}.png"
    />
  </div>
  <div
    class="dropdown-menu {{
      hasLabel ? 'dropdown-menu-left' : 'dropdown-menu-right'
    }}"
    aria-labelledby="dropdown05"
  >
    <a
      *ngFor="let flag of countryFlags"
      class="dropdown-item menu-filter-clicked"
      (click)="selectLanguage(flag)"
    >
      <img
        class="img-fluid flags rounded-circle mr-2"
        alt="Country flag"
        src="assets/media/countriesFlags/{{ flag.key }}.png"
      />
      {{ flag.label }}
    </a>
  </div>
</div>
<div *ngIf="readonly">
  <img
    class="img-fluid flags rounded-circle"
    alt="Country flag"
    src="assets/media/countriesFlags/{{ selectedCountry.key }}.png"
  />
  <span *ngIf="hasLabel" class="ml-3">{{ selectedCountry.label }}</span>
</div>
<app-ka-frontend-version *ngIf="isNuevoPedidoPath"></app-ka-frontend-version>
