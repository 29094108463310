import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { SelectClientService } from 'src/app/core/services/select-client.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ka-select-commerce',
  templateUrl: './ka-select-commerce.component.html',
  styleUrls: ['./ka-select-commerce.component.scss'],
})
export class KaSelectCommerceComponent implements OnDestroy {
  @Input() minimalist? = false;
  @Input() hasAllowedRole = true;
  private subscriptions = new Subscription();
  suggestedProducts: any[] = [];
  clients: Client[];
  selectedClient: Client;

  constructor(private store: Store<{ user: UserInfo; client: Client }>, private selectClientService: SelectClientService, ngbMenu: NgbDropdownConfig) {
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.clients = user.clients)));
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.selectedClient = client)));
    ngbMenu.placement = 'bottom';
  }

  selectClient(client): void {
    if (client.clientId === this.selectedClient.clientId) {
      return;
    }
    this.selectClientService.selectClient(client);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
