import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { BERespModel } from 'src/app/core/models/backend/BE-response.model';
import { UserExternalData } from 'src/app/core/models/user-external-info.model';

import { ApiService } from 'src/app/core/services/api.service';
import { ClientService } from 'src/app/core/services/client.service';

import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { ExternalIntegration } from '../enums/external-integration.enum';

import { env } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class ExternalDataService {
  subscriptions = new Subscription();
  private validateExternalUserSubject = new BehaviorSubject<BERespModel>({} as BERespModel);
  validateExternalIntegrationUser$ = this.validateExternalUserSubject.asObservable();

  getExternalUserValidationData(): Observable<any> {
    return this.validateExternalIntegrationUser$;
  }

  constructor(
    private apiSrv: ApiService,
    private clientService: ClientService,
    private router: Router,
  ) { }

  validateExternalIntegrationUser(uuid: string): Observable<BERespModel> {
    const requestObj = { session: uuid };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${this.clientService.client.clientId}/external/validate`, EndpointsCodes.LOGIN_EXTERNAL_INTEGRATIONS, requestObj, { showError: false }).subscribe(
        (res) => {
          this.validateExternalUserSubject.next(res);
          obs.next(res);
        },
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  getExternalIntegrationUserInfo(clientId: string, authValue: string, authType: string): Observable<BERespModel> {
    const requestObj = { authValue: authValue, authType: authType };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${clientId}/external/user_info`, EndpointsCodes.POST_EXTERNAL_USER_INFO, requestObj, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  createUserSession(data: UserExternalData): Observable<BERespModel> {
    const requestObj = {
      origin: data.origin,
      accessToken: data.AccessToken,
      idToken: data.IdToken,
      refreshToken: data.RefreshToken,
      contextId: data.contextId,
      authType: data.authType,
      authValue: data.authValue
    };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${this.clientService.client.clientId}/external/session`, EndpointsCodes.POST_EXTERNAL_USER_SESSION, requestObj, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  isExternalIntegrationModule(): boolean {
    return this.router.url.includes(ExternalIntegration.EXTERNAL);
  }

  goBackToWhatsapp(): void {
    if (!this.isExternalIntegrationModule()) {
      return;
    }

    const phoneNumber = env.getEnvironment().kobossWhatsapp;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    
    window.location.href = whatsappUrl;
  }

  updateClientStore$(): Observable<void> {
    return new Observable<void>((obs) => {
      this.validateExternalIntegrationUser$.subscribe({
        next: ({ data }) => {
          const PARAMS = {
            clientId: this.clientService.client.clientId.toString(),
            authValue: data.authValue,
            authType: data.authType,
          };

          this.getExternalIntegrationUserInfo(
            PARAMS.clientId,
            PARAMS.authValue,
            PARAMS.authType
          ).subscribe({
            next: ({ data }) => {
              this.clientService.updateCurrentClient(data)
              obs.next();
            },
            error: () => {
              obs.error();
            }
          });
        }
      })
    });
  }
}
