import {Component, ElementRef, EventEmitter, HostListener, OnDestroy, Output, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { TycModalComponent } from 'src/app/pages/login/components/tyc-modal/tyc-modal.component';

import { UserService } from 'src/app/core/services/user.service';
import { NavbarMenuMobileService } from './services/navbar-menu-mobile.service';
import { ModalMobileDeliveryDateService } from '../select-delivery-date/services/modal-mobile-delivery-date.service';

@Component({
  selector: 'app-ka-navbar-menu-mobile',
  templateUrl: './ka-navbar-menu-mobile.component.html',
  styleUrls: ['./ka-navbar-menu-mobile.component.scss'],
})
export class KaNavbarMenuMobileComponent implements OnDestroy {
  user: UserInfo;
  client: Client;
  isNewOrder: Boolean = false;
  hasAllowedRole: Boolean = false;
  private subscriptions = new Subscription();
  @ViewChild("MobileMenu") insideElement: ElementRef<HTMLDivElement>;
  @Output() openModal: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    this._navbarMenuMobileService.updateHistoryForModal();
    const navbarSupportedContent = document.getElementById('navbarSupportedContent');
    const clickedInside = this.insideElement.nativeElement.contains(targetElement);
    let flagOpenedMenu = navbarSupportedContent.classList.contains('show');
    if (
      !clickedInside &&
      flagOpenedMenu &&
      screen.width < 992 &&
      this._navbarMenuMobileService.canCloseSidebar()
    ) {
      this._navbarMenuMobileService.openCloseNavBarMenuMobile();
    }
  }
  
  constructor(
    private router: Router,
    private store: Store<{ user: UserInfo; client: Client }>,
    private modalService: NgbModal,
    private userService: UserService,
    private _navbarMenuMobileService: NavbarMenuMobileService,
    public _modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects.split('/')[2];
        this.isNewOrder = currentRoute === 'nuevo-pedido';
      }
    });
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.hasAllowedRole = this.user.role !== 'Buyer';
      })
    );
  }


  openTyCModal(): void {
    const modalTyc = this.modalService.open(TycModalComponent, { windowClass: 'ngbmodal-centered', size: 'xl' });
    modalTyc.componentInstance.data = { hideActions: true };
  }

  signOut(): void {
    this.userService.signOut();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
