<div class="title-page">
  <svg (click)="clickEvent.emit()" class="icon" stroke-width="1.5">
    <use
      [attr.xlink:href]="
        './assets/icons/tabler-sprite.svg#tabler-' + config.icon
      "
    />
  </svg>
  <h6>{{ config.text }}</h6>
</div>
