export type environmentName = 'DEV' | 'TEST' | 'STAGE' | 'QA' | 'PROD';
export type countries = 'CL' | 'AR' | 'BR' | 'PY';
export enum FEATURES {
  STOCK_DIGITAL = 'STOCK_DIGITAL',
  THANOS_ORDER_DETAIL = 'THANOS_ORDER_DETAIL',
  DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',
  CRM = 'CRM',
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
  CATEGORY_CAROUSEL = 'CATEGORY_CAROUSEL',
  CATEGORY_BUTTON_CAROUSEL = 'CATEGORY_BUTTON_CAROUSEL',
  CATEGORY_ICON_CAROUSEL = 'CATEGORY_ICON_CAROUSEL',
  DIGITAL_PAYMENTS = 'DIGITAL_PAYMENTS',
  MY_WALLET = 'MY_WALLET',
  NPS_SURVEY = 'NPS_SURVEY',
  REWARDS = 'REWARDS',
  UPDATE_MIN_PURCHASE = 'UPDATE_MIN_PURCHASE',
  DIGITAL_PROMOTIONS = 'DIGITAL_PROMOTIONS',
  PAYMENT_METHODS_BY_CATEGORY = 'PAYMENT_METHODS_BY_CATEGORY',
  SCANNER_QR = 'SCANNER_QR',
  PAYMENT_METHODS_BY_CATEGORY_ORDER_DETAIL = 'PAYMENT_METHODS_BY_CATEGORY_ORDER_DETAIL',
  NEW_S2_PORTFOLIO = 'NEW_S2_PORTFOLIO',
  NEWSLETTERS = 'NEWSLETTERS'
}

export interface IEnvironment {
  production: boolean;
  type: environmentName;
  countriesConfig: CountriesConfig;
  BASE_URL_PUBLIC: string;
  BASE_URL_INTERNAL: string;
  BASE_URL_INTEGRATIONS: string;
  AWS_REGION: string;
  SIGN_IN_CALLBACK: string;
  SIGN_OUT_URL: string;
  AES_KEY: string;
  AES_KEY_MCC: string;
  WEB_DOMAIN: string;
  GTM_ID: string;
  ORDER_LIST_INIT_PATTERN: string;
  DATA_SURVEY: string;
  DIGITAL_PAYMENTS_URL: string;
  checkForNewVersionInterval: number;
  sessionTimeout: number;
  enableServiceWorker: boolean;
  kobossWhatsapp: number;
  clarityId: string;
}

export interface CountriesConfig {
  CL: CountryConfig;
  AR: CountryConfig;
  BR: CountryConfig;
  PY: CountryConfig;
}

export interface CountryConfig {
  awsUserPoolId: string;
  awsClientId: string;
  awsClientIdMCC?: string;
  awsAuthFlow: 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH';
  oauthDomain: string;
  identityPoolId: string;
  key: countries;
  label: string;
  lang: string;
  organizationId: string;
  cpgId: string;
  phoneRegex: RegExp | RegExp[];
  homeStyle: number;
  fiscalIdRegex: RegExp[] | string;
  minPurchase: number;
  hasOffRoute: boolean;
  maxlengthFiscalId?: number;
  productsPerPagePortfolio: number;
  visitPlanLength: number;
  availableFeatures: FEATURES[];
  minPurchaseFrozen?: number;
  surveyId?: string;
}
