<app-ka-draggable-carousel>
  <ng-container *ngIf="categories">
    <ng-container *ngIf="categoryButtonCarousel">
      <app-ka-categories-button
        *ngIf="currentCategory !== 'promociones'"
        [text]="currentCategory"
        yarn
        [image]="
          IMG_ROOT +
          'categories/' +
          user.countryId +
          '/' +
          getCategoryImageName(currentCategory) +
          '.png'
        "
        (clickEvent)="goToCategory($event, page !== 'home')"
        [selected]="page !== 'home'"
      ></app-ka-categories-button>
      <app-ka-categories-button
        [text]="ROOT_LANG + 'PROMOS' | translate"
        [image]="IMG_ROOT + 'categories/' + user.countryId + '/promociones.png'"
        (clickEvent)="
          goToCategory(
            'promociones',
            currentCategory === 'promociones' && page !== 'home'
          )
        "
        [selected]="currentCategory === 'promociones' && page !== 'home'"
      ></app-ka-categories-button>
      <ng-container *ngFor="let category of categories; index">
        <app-ka-categories-button
          *ngIf="currentCategory !== category"
          [text]="category"
          [image]="
            IMG_ROOT +
            'categories/' +
            user.countryId +
            '/' +
            getCategoryImageName(category) +
            '.png'
          "
          [alt]="'Imagen de ' + category"
          (clickEvent)="
            goToCategory(
              $event,
              currentCategory === category && page !== 'home'
            )
          "
          [selected]="currentCategory === category && page !== 'home'"
        ></app-ka-categories-button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="categoryIconCarousel">
      <app-ka-categories-icon
        *ngIf="currentCategory !== 'promociones'"
        [text]="currentCategory"
        yarn
        [image]="
          IMG_ROOT +
          'categories/' +
          user.countryId +
          '/icons/' +
          getCategoryImageName(currentCategory) +
          '.png'
        "
        (clickEvent)="goToCategory($event, page !== 'home')"
        [selected]="page !== 'home'"
      ></app-ka-categories-icon>
      <app-ka-categories-icon
        [text]="ROOT_LANG + 'PROMOS' | translate"
        [image]="
          IMG_ROOT + 'categories/' + user.countryId + '/icons/promociones.png'
        "
        (clickEvent)="
          goToCategory(
            'promociones',
            currentCategory === 'promociones' && page !== 'home'
          )
        "
        [selected]="currentCategory === 'promociones' && page !== 'home'"
      ></app-ka-categories-icon>
      <app-ka-categories-icon
        *ngIf="hasSuggestedProducts"
        [category]="'suggested'"
        [text]="ROOT_LANG + 'SUGGESTED' | translate"
        [image]="
          IMG_ROOT + 'categories/' + user.countryId + '/icons/sugeridos.png'
        "
        (clickEvent)="goToCategorySuggested('2')"
      ></app-ka-categories-icon>
      <ng-container *ngFor="let category of categories; index">
        <app-ka-categories-icon
          *ngIf="currentCategory !== category"
          [text]="category"
          [image]="
            IMG_ROOT +
            'categories/' +
            user.countryId +
            '/icons/' +
            getCategoryImageName(category) +
            '.png'
          "
          [alt]="'Imagen de ' + category"
          (clickEvent)="
            goToCategory(
              $event,
              currentCategory === category && page !== 'home'
            )
          "
          [selected]="currentCategory === category && page !== 'home'"
        ></app-ka-categories-icon>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!categories">
    <ng-container *ngFor="let skeleton of skeletonDummy">
      <app-ka-skeleton-category-button
        class="m-3"
      ></app-ka-skeleton-category-button>
    </ng-container>
  </ng-container>
</app-ka-draggable-carousel>
