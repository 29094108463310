import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointsCodes } from '../../enums/endpoints-codes.enum';
import { OptionsMet } from '../../models/met/met.model';

import { ApiService } from '../api.service';

@Injectable()
export class MetService {
  constructor(private apiService: ApiService) { }

  getOptionsMet(clientId: number): Observable<{ data: OptionsMet[] }> {
    return this.apiService.get(
      `clients/${clientId}/loyalty`,
      EndpointsCodes.GET_OPTIONS_MET,
      { ngxSpinner: false, showError: false }
    ) as Observable<{ data: OptionsMet[] }>;
  }
}
