<div class="suggested-button w-100 p-4 card d-flex flex-column flex-md-row align-items-end align-items-md-center justify-content-between">
  <div class="d-flex flex-row align-items-center suggested-main">
    <div class="suggested-img mr-3">
      <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-sugerido'" /></svg>
    </div>
    <div class="suggested-text d-flex flex-column align-items-start justify-content-start">
      <p class="title">{{ ROOT_LANG + 'TITLE' | translate }}</p>
      <p *ngIf="selectedClient.data.suggestedProducts?.products?.length" class="subtitle">{{ ROOT_LANG + 'SUBTITLE' | translate }}</p>
    </div>
  </div>
  <div class="suggested-cta d-flex flex-row align-items-center flex-end">
    <span>{{ ROOT_LANG + 'SPAN' | translate }}</span>
    <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-right'" /></svg>
  </div>
</div>
