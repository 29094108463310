import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { ModalDesktopFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { ModalMobileFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { ModalDesktopDeliveryDateService } from '../select-delivery-date/services/modal-desktop-delivery-date.service';
import { ModalMobileDeliveryDateService } from '../select-delivery-date/services/modal-mobile-delivery-date.service';
import { VisitDatesService } from 'src/app/core/services/visit-date.service';

@Component({
  selector: 'app-exp-timer-warn',
  templateUrl: './exp-timer-warn.component.html',
  styleUrls: ['./exp-timer-warn.component.scss'],
})
export class ExpTimerWarnComponent implements OnInit, OnDestroy {
  cart: Cart;
  client: Client;
  user: UserInfo;
  private subscriptions = new Subscription();
  timeRemaining: any;
  timeRemainingInterval;
  isChileanUser: boolean;
  readonly ROOT = 'NEW_ORDER.RIGHT_SIDEBAR.';
  readonly CountryCodes = CountryCodes;
  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }
  @Input()small: boolean;

  constructor(
    private store: Store<{ cart: Cart; client: Client; user: UserInfo }>,
    private ngModal: NgbModal,
    private _isMobileService: IsMobileService,
    private _visitDateService: VisitDatesService,
    private _modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService,
    private _modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    private _modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private _modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  ngOnInit(): void {
    this.timeRemainingInterval = setInterval(() => {
      if (this.cart.invoiceDeadline === '') this.store.dispatch(ClientActions.updateOperationDatesSuccess());

      this.getTimeRemaining(this.cart.invoiceDeadline);
    }, 1000);
    this.isChileanUser = this.user.countryId === this.CountryCodes.CHILE;
  }

  getTimeRemaining(invoiceDeadline: any): any {
    if (!this._visitDateService.isFirstDeliveryDate()) {
      this.timeRemaining = undefined;
      return;
    }
    const total = new Date(invoiceDeadline).getTime() - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / (1000 * 60 * 60));

    if (total < 0) return;

    this.timeRemaining = {
      hours,
      minutes: minutes < 10 ? '0' + minutes : minutes,
      seconds: seconds < 10 ? '0' + seconds : seconds,
    };
  }

  openSelectFrozenDeliveryDateModal(): void {
    this.isMobile
      ? this._modalMobileFrozenDeliveryDateService.openModal()
      : this._modalDesktopFrozenDeliveryDateService.openModal();
  }

  openSelectDeliveryDateModal() {
    this.isMobile
      ? this._modalMobileDeliveryDateService.openModal()
      : this._modalDesktopDeliveryDateService.openModal();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeRemainingInterval);
    this.subscriptions.unsubscribe();
  }

}