<div
    class="w-100 d-flex flex-row align-items-center mb-3 quickaccess-container"
>
  <div *ngFor="let button of buttons"> 
    <app-ka-quick-access-button
      *ngIf="button.isSuggested"
       [quickAccess] = "button"
    >
    </app-ka-quick-access-button>
  </div>
</div>
