<!-- GRID VIEW -->
<div
  *ngIf="style === 'GRID'"
  class="card-square d-flex flex-column container"
  [ngClass]="{ carousel: carousel }"
>
  <div class="row image">
    <div
      class="d-none d-lg-block info mb-5"
      placement="top"
      ngbTooltip="Tooltip on top"
    ></div>
    <div
      class="image-discount d-flex align-items-center justify-content-center"
    >
      <img
        *ngIf="discount.discountType !== DiscountTypes.BOM"
        class="mw-100"
        [ngClass]="{ carousel: carousel }"
        [src]="discount.image | toThumbnail"
        alt=""
        (error)="onImgError()"
        (click)="openImage()"
      />
      <img
        *ngIf="discount.discountType === DiscountTypes.BOM"
        class="mw-100"
        [src]="discount.image | toThumbnail"
        alt="Combo"
        (error)="onImgError()"
        (click)="openImage()"
      />
    </div>
  </div>
  <div class="discount-data {{ discount.discountType }}">
    <div
      *ngIf="scales && discount.discountType !== DiscountTypes.BOM"
      class="w-100 mt-3"
    >
      <app-ka-status-pill
        [status]="'NEW_ORDER.DISCOUNTS.SCALE.SAVE'"
        [isDiscount]="true"
      ></app-ka-status-pill>
    </div>
    <div *ngIf="discount.discountType === DiscountTypes.BOM" class="w-100 mt-3">
      <app-ka-status-pill
        [status]="discountPill"
        [isDiscount]="true"
      ></app-ka-status-pill>
    </div> 
    <div
      class="discount-label"
      *ngIf="discount.discountType === DiscountTypes.SCALE_AMOUNT"
    >
      {{ discount?.requirements?.name }}
    </div>
    <div
      class="discount-label"
      *ngIf="discount.discountType !== DiscountTypes.BOM"
    >
      {{ discount.name }}
    </div>
    <div
      class="discount-label"
      *ngIf="discount.discountType === DiscountTypes.BOM"
      [ngbTooltip]="discountName"
      tooltipPosition="bottom"
      tooltipClass="custom-tooltip"
    >
      {{ discountName | toTruncate: 75 }}
    </div>
    <div
      *ngIf="discount.discountType === DiscountTypes.BOM"
      class="product-price d-flex flex-column mt-1"
    >
      <br />
      <small
        *ngIf="discount.price.listPrice > discount.price.finalPrice"
        class="d-inline-block mr-3"
        >{{ discount.price.listPrice | kaCurrency }}</small
      >
      <small
        *ngIf="discount.price.listPrice === discount.price.finalPrice"
        class="d-inline-block mr-3"
        ><br />
      </small>
      <span>{{ discount.price.finalPrice | kaCurrency }}</span>
    </div>
    <div
      class="discount-label"
      *ngIf="
        (!scales ||
          !lastScale ||
          discount.discountType === DiscountTypes.OPEN ||
          discount.discountType === DiscountTypes.PACK_SCALE ||
          discount.discountType === DiscountTypes.PERCENTAGE ||
          discount.discountType === DiscountTypes.AMOUNT ||
          discount.discountType === DiscountTypes.FIXED) &&
        discount.discountType !== DiscountTypes.BOM
      "
    >
      {{ 'NEW_ORDER.DISCOUNTS.OPEN.LABEL' | translate }}
    </div>

    <div
      class="discount-label"
      *ngIf="discount.discountType === DiscountTypes.UNIT_SCALE"
    >
      {{
        currentScaleLabel
          ? ('NEW_ORDER.DISCOUNTS.SCALE.' + currentScaleLabel
            | translate
              : {
                  scale: !!currentScale.reward
                    ? currentScale
                    : isCurrentScaleLast
                    ? prevScale
                    : nextScale
                })
          : discount.requirements.name
      }}
    </div>
    <!-- CLOSED, BOM, PERCENTAJE, AMOUNT -->
    <div *ngIf="discount | showDiscountLabelForCC">
      <p class="discount-label mt-3">
        {{
          'NEW_ORDER.DISCOUNTS.CLOSED.DISCOUNT_LABEL'
            | translate
              : {
                  discount:
                    (1 -
                      (currentScale
                        | getTotalFinalPriceCC: scales : discount : lastScale) /
                        (discount | getTotalListPriceCC)) *
                      100 | number: '1.0-1'
                }
        }}
      </p>
      <p class="discount-limit">
        {{
          'NEW_ORDER.DISCOUNTS.CLOSED.LIMIT'
            | translate: { limit: discount.maxRepetitionAllowed }
        }}
      </p>
    </div>

    <!-- CLOSED, PERCENTAJE, AMOUNT -->
    <ng-container *ngIf="discount | showDiscountLabelForCC">
      <div class="price">
        <div class="pack-price d-flex flex-column">
          <small>{{ discount | getTotalListPriceCC | kaCurrency }}</small>
          <span>{{
            currentScale
              | getTotalFinalPriceCC: scales : discount : lastScale
              | kaCurrency
          }}</span>
        </div>
        <small class="text-secondary">{{
          'NEW_ORDER.DISCOUNTS.CLOSED.UNIT'
            | translate: { price: discount | getUnitPriceCC | kaCurrency }
        }}</small>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        currentScale &&
        (discount.discountType === DiscountTypes.UNIT_SCALE ||
          discount.discountType === DiscountTypes.SCALE_AMOUNT)
      "
    >
      <div class="price">
        <div class="pack-price d-flex flex-column">
          <small
            *ngIf="
              currentScale?.price?.finalPriceWithoutDiscount *
                discount.quantitySelected !==
              (discount | getScaleDiscountPrice: currentScale)
            "
          >
            {{
              currentScale?.price.finalPriceWithoutDiscount *
                discount.quantitySelected | kaCurrency
            }}
          </small>

          <span>{{
            discount | getScaleDiscountPrice: currentScale | kaCurrency
          }}</span>
        </div>
        <small class="text-secondary">{{
          'NEW_ORDER.DISCOUNTS.CLOSED.UNIT'
            | translate
              : {
                  price: discount | getScaleUnitPrice: currentScale | kaCurrency
                }
        }}</small>
      </div>
    </ng-container>
  </div>
  <div
    class="row discount-quantity mt-auto d-flex align-items-center justify-content-center mb-3"
    *ngIf="!carousel"
  >
    <ng-container
      *ngIf="
        (discount | showAddButtonOnCard) &&
        discount.discountType !== DiscountTypes.BOM
      "
    >
      <div
        class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-auto bg-white text-center"
      >
        <span
          (click)="removeProduct()"
          class="button d-flex align-items-center justify-content-center"
          [ngClass]="{ disabled: discount.quantitySelected === 1 }"
        >
          <fa-icon icon="minus"></fa-icon>
        </span>
        <input
          type="number"
          [(ngModel)]="discount.quantitySelected"
          (input)="updateScale()"
          (keydown)="validQuantLength($event)"
          (paste)="onQuantityPaste($event)"
        />
        <span
          (click)="addProduct()"
          class="button d-flex align-items-center justify-content-center"
        >
          <fa-icon icon="plus"></fa-icon>
        </span>
      </div>
      <button
        [disabled]="!(discount | isQuantitySelectedValid)"
        class="btn-pill-add w-auto ml-3 p-4 px-5"
        (click)="addProductToCart(discount)"
      >
        {{ 'NEW_ORDER.ORDER_DETAIL.ADD' | translate }}
      </button>
    </ng-container>
    <ng-container
      *ngIf="
        (discount | showSelectButtonOnCard) &&
        discount.discountType !== DiscountTypes.BOM
      "
    >
      <button
        class="btn-pill-add w-100 p-4 mx-5 my-2"
        (click)="addProductToCart(discount)"
        [id]="discount.discountType"
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.BUTTON' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="discount.discountType === DiscountTypes.BOM">
      <app-ka-add-to-cart-button
        class="bom-grid"
        [product]="bomInCart"
      ></app-ka-add-to-cart-button>
    </ng-container>
  </div>
</div>

<!-- ROW VIEW -->
<div *ngIf="style === 'ROW'" class="card-row bg-white p-1">
  <div class="row justify-content-center align-items-center">
    <div class="row col-4 col-md-2">
      <img
        *ngIf="discount.discountType !== DiscountTypes.BOM"
        class="img-fluid"
        [src]="discount.image | toThumbnail"
        alt=""
        (error)="onImgError()"
        (click)="openImage()"
      />
      <img
        *ngIf="discount.discountType === DiscountTypes.BOM"
        class="mw-100"
        [src]="'../../../assets/media/image.png'"
        alt="Combo"
        (error)="onImgError()"
        (click)="openImage()"
      />
    </div>
    <div class="row col-8 col-md-10">
      <div
        *ngIf="scales && discount.discountType !== DiscountTypes.BOM"
        class="w-100 mt-3"
      >
        <app-ka-status-pill
          [status]="'NEW_ORDER.DISCOUNTS.SCALE.SAVE'"
          [isDiscount]="true"
        ></app-ka-status-pill>
      </div>
      <div class="col-12 col-md-5 p-1 pl-3">
        <div
          *ngIf="discount.discountType === DiscountTypes.BOM"
          class="w-100 mt-3"
        >
          <app-ka-status-pill
            [status]="discountPill"
            [isDiscount]="true"
          ></app-ka-status-pill>
        </div>
        <div class="discount-data bg-white">
          <div
              class="discount-label"
              *ngIf="discount.discountType === DiscountTypes.SCALE_AMOUNT"
            >
                {{ discount?.requirements?.name }}
            </div>
             <div
                class="discount-label"
                *ngIf="discount.discountType !== DiscountTypes.BOM"
              >
                {{ discount.name }}
             </div>
            <div
                class="discount-label"
                *ngIf="discount.discountType === DiscountTypes.BOM"
                [ngbTooltip]="discountName"
                tooltipPosition="bottom"
                tooltipClass="custom-tooltip"
              >
                {{ discountName | toTruncate: 90 }}
          </div>

          <span
            class="discount-label"
            *ngIf="
              discount.discountType === DiscountTypes.OPEN ||
              (discount.discountType === DiscountTypes.PACK_SCALE &&
                discount.discountType !== DiscountTypes.BOM)
            "
          >
            {{ 'NEW_ORDER.DISCOUNTS.OPEN.LABEL' | translate }}
          </span>
          <span
            class="discount-label"
            *ngIf="discount.discountType === DiscountTypes.UNIT_SCALE"
          >
            {{
              'NEW_ORDER.DISCOUNTS.SCALE.' + currentScaleLabel
                | translate
                  : {
                      scale: !!currentScale?.reward.value
                        ? currentScale
                        : isCurrentScaleLast
                        ? prevScale
                        : nextScale
                    }
            }}
          </span>
          <!-- CLOSED, BOM, PERCENTAJE, AMOUNT -->
          <div *ngIf="discount | showDiscountLabelForCC">
            <p class="discount-label">
              {{
                'NEW_ORDER.DISCOUNTS.CLOSED.DISCOUNT_LABEL'
                  | translate
                    : {
                        discount:
                          (1 -
                            (currentScale
                              | getTotalFinalPriceCC
                                : scales
                                : discount
                                : lastScale) /
                              (discount | getTotalListPriceCC)) *
                            100 | number: '1.0-1'
                      }
              }}
            </p>
            <p class="discount-limit">
              {{
                'NEW_ORDER.DISCOUNTS.CLOSED.LIMIT'
                  | translate: { limit: discount.maxRepetitionAllowed }
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 d-flex p-1 discount-price-cont">
        <div class="row discount-data bg-white">
          <div
            *ngIf="discount.discountType === DiscountTypes.BOM"
            class="product-price d-flex flex-column mt-1"
          >
            <br />
            <small
              *ngIf="discount.price.listPrice > discount.price.finalPrice"
              class="d-inline-block mr-3"
              >{{ discount.price.listPrice | kaCurrency }}</small
            >
            <small
              *ngIf="discount.price.listPrice === discount.price.finalPrice"
              class="d-inline-block mr-3"
              ><br />
            </small>
            <span>{{ discount.price.finalPrice | kaCurrency }}</span>
          </div>

          <div class="discount-price p-0 pl-2 text-center">
            <!-- CLOSED, PERCENTAJE, AMOUNT -->
            <ng-container *ngIf="discount | showDiscountLabelForCC">
              <div class="price">
                <div class="pack-price d-flex flex-column">
                  <small>{{
                    discount | getTotalListPriceCC | kaCurrency
                  }}</small>
                  <span>{{
                    currentScale
                      | getTotalFinalPriceCC: scales : discount : lastScale
                      | kaCurrency
                  }}</span>
                </div>
                <small class="text-secondary">{{
                  'NEW_ORDER.DISCOUNTS.CLOSED.UNIT'
                    | translate
                      : { price: discount | getUnitPriceCC | kaCurrency }
                }}</small>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                discount.discountType === DiscountTypes.UNIT_SCALE ||
                discount.discountType === DiscountTypes.SCALE_AMOUNT
              "
            >
              <div class="price">
                <div class="pack-price d-flex flex-column">
                  <small
                    *ngIf="
                      currentScale?.price?.listPrice *
                        discount.quantitySelected !==
                      (discount | getScaleDiscountPrice: currentScale)
                    "
                  >
                    {{
                      currentScale?.price?.listPrice * discount.quantitySelected
                        | kaCurrency
                    }}
                  </small>
                  <span>{{
                    discount | getScaleDiscountPrice: currentScale | kaCurrency
                  }}</span>
                </div>
                <small class="text-secondary">{{
                  'NEW_ORDER.DISCOUNTS.CLOSED.UNIT'
                    | translate
                      : {
                          price:
                            discount
                            | getScaleUnitPrice: currentScale
                            | kaCurrency
                        }
                }}</small>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-center p-1">
        <div
          class="row discount-quantity w-100 d-flex align-items-center justify-content-center"
        >
          <ng-container
            *ngIf="
              (discount | showAddButtonOnCard) &&
              discount.discountType !== DiscountTypes.BOM
            "
          >
            <div
              class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-100 bg-white text-center"
            >
              <span
                (click)="removeProduct()"
                class="button d-flex align-items-center justify-content-center"
                [ngClass]="{ disabled: discount.quantitySelected === 1 }"
              >
                <fa-icon icon="minus"></fa-icon>
              </span>
              <input
                type="number"
                [(ngModel)]="discount.quantitySelected"
                (input)="updateScale()"
                (keydown)="validQuantLength($event)"
                (paste)="onQuantityPaste($event)"
              />
              <span
                (click)="addProduct()"
                class="button d-flex align-items-center justify-content-center"
              >
                <fa-icon icon="plus"></fa-icon>
              </span>
            </div>
            <button
              [disabled]="!(discount | isQuantitySelectedValid)"
              class="btn-pill-add btn-pill-add-enabled p-4 w-100 mt-2"
              (click)="addProductToCart(discount)"
            >
              {{ 'NEW_ORDER.ORDER_DETAIL.ADD' | translate }}
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              (discount | showSelectButtonOnCard) &&
              discount.discountType !== DiscountTypes.BOM
            "
          >
            <button
              class="btn-pill-add w-100 p-4"
              (click)="addProductToCart(discount)"
            >
              {{ 'NEW_ORDER.DISCOUNTS.OPEN.BUTTON' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="discount.discountType === DiscountTypes.BOM">
            <app-ka-add-to-cart-button
              [product]="bomInCart"
              [bomRowView]="true"
            ></app-ka-add-to-cart-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
