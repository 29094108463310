import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { TycModalComponent } from 'src/app/pages/login/components/tyc-modal/tyc-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrincipalMenuService } from 'src/app/core/services/principal-menu.service';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { UserService } from 'src/app/core/services/user.service';
import { SidebarMetService } from './services/sidebar-met.service';
import { SubmenuService } from './services/submenu.service';
import { DateUtility } from 'src/app/core/utils/app-date-utils';

@Component({
  selector: 'app-ka-sidebar',
  templateUrl: './ka-sidebar.component.html',
  styleUrls: ['./ka-sidebar.component.scss'],
})
export class KaSidebarComponent implements OnInit, OnDestroy {
  @Input() isMobile = false;
  sidebarOptions: any[] = [];
  isNewOrderSelected = false;
  hasAllowedRole = false;
  client: Client;
  cart: Cart;
  user: UserInfo;

  private subscriptions = new Subscription();
  readonly DateUtility = DateUtility;
  readonly CountryCodes = CountryCodes;

  constructor(
    private router: Router,
    private store: Store<{ user: UserInfo; client: Client; cart: Cart }>,
    private modalService: NgbModal,
    private userService: UserService,
    private principalMenuService: PrincipalMenuService,
    private sidebarMetService: SidebarMetService,
    private subMenuService: SubmenuService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.hasAllowedRole = this.user.role !== 'Buyer';
        this.sidebarOptions.forEach((opt) => {
          if (opt.icon === 'shopping-cart')
            opt.disabled =
              this.client.hasLockOrder ||
              this.client.data.visitDates.length === 0 ||
              this.client.data.hasCreditLock;
        });
      }),
    );
  }

  ngOnInit(): void {
    this.sidebarOptions = this.principalMenuService.getPrincipalMenuItems(
      this.isMobile,
    );
    this.updateNavOptions(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateNavOptions(event.urlAfterRedirects);
      }
      if (event instanceof NavigationCancel) {
        this.updateNavOptions(event.url);
      }
    });
    this.sidebarMetService.initMetLogic();
    this.sidebarMetService.setOptionsMetUp(this.sidebarOptions);
    this.sidebarMetService.showItemMenuDigitalPromotions(this.sidebarOptions);
  }

  openCloseSubMenu(option: any): void {
    this.subMenuService.openCloseItem(option);
  }

  goToIframe(url: string):void {
    this.sidebarMetService.saveURLintoStore(url);
  }

  updateNavOptions(fullRoute): void {
    const currentRoute = fullRoute.split('/')[2];
    const innerPathOnRoute = fullRoute.split('/')[3];
    this.isNewOrderSelected =
      currentRoute === 'nuevo-pedido' || innerPathOnRoute === 'detalles';
    this.sidebarOptions.forEach((opt) => {
      opt.active = opt.routerLink?.split('/')[0] === currentRoute;
    });
  }

  optionSelected(optSelected): void {
    if (optSelected.disabled) return;
    if (!optSelected.external) {
      this.router.navigate([
        '/main/' +
          optSelected.routerLink.replace(':clientId', this.client.clientId),          
      ]);
    } else {
      window.open(optSelected.routerLink, '_self');
    }
  }

  isDeadlineToday(): boolean {
    return (
      moment(this.cart.invoiceDeadline).format('YYYYMMDD') ===
      moment().format('YYYYMMDD')
    );
  }

  isFirstDeliveryDate(): boolean {
    const visitDateIndex = this.client.data.visitDates.findIndex((date) => {
      return moment(date.visitDate).isSame(moment(this.cart.visitDate));
    });
    return visitDateIndex === 0;
  }

  signOut(): void {
    this.unSubscribeAllObservables();
    this.userService.signOut();
  }

  private unSubscribeAllObservables(): void {
    this.sidebarMetService.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  openTyCModal(): void {
    const modalTyc = this.modalService.open(TycModalComponent, {
      windowClass: 'ngbmodal-centered',
      size: 'xl',
    });
    modalTyc.componentInstance.data = { hideActions: true };
  }

  ngOnDestroy(): void {
    this.unSubscribeAllObservables();
  }
}
