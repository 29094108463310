<ul class="options">
  <ng-template
    ngFor 
    let-option 
    [ngForOf]="sidebarOptions" 
    let-index="index"
    >
    <li 
      *ngIf="option.show && !option.options;"
      class="li-sidebar" 
      [ngClass]="{ active: option.active, disabled: option.disabled }" 
      (click)="optionSelected(option)"
      >
      <svg class="img-fluid mr-2 icon-ka-sidebar" stroke-width="3.25">
        <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-' + option.icon" />
      </svg>

      <span>{{ 'HOME.' + option.langKey | translate }}</span>
    </li>

    <ng-container *ngIf="option.show && option.options;">
      <li
        class="li-sidebar"
        id="{{'itemSubmenuMain-' + index}}"
        [ngClass]="{ active: option.active, disabled: option.disabled }"
        (click)="openCloseSubMenu(option)"
        >
        <svg class="img-fluid mr-2 icon-ka-sidebar" stroke-width="3.25">
          <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-' + option.icon"/>
        </svg>

        <span>{{ 'HOME.' + option.langKey | translate }}</span>

        <svg 
          class="submenu-icon-main {{option?.open ? 'open' : null}}"
          stroke-width="3.25">
          <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-down'"/>
        </svg>
      </li>
      <ng-container *ngIf="option?.open">
        <li 
          *ngFor="let item of option.options"  
          class="subItemMenu"
          >
          <svg class="mr-2">
            <use attr.xlink:href="{{'./assets/icons/tabler-sprite.svg#' + item.icon }}"/>
          </svg>
          <a (click)="goToIframe(item.url)">{{ item.label }}</a>
        </li>
      </ng-container>
    </ng-container>
  </ng-template>
</ul>
