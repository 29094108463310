<div class="modal-header">
  <h3 class="modal-title mt-4" id="modal-basic-title">
    {{ ROOT_LANG + title | translate }}
  </h3>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body mat-dialog-container">
  <iframe
    id="tyc-frame"
    title="tyc-frame"
    *ngIf="iframeSource && showIframe"
    class="w-100 h-100"
    [src]="iframeSource"
    (load)="iframeLoad()"
  ></iframe>
</div>

<div *ngIf="nameBtn !== ''">
  <div class="modal-footer row d-none d-md-flex pb-4 px-5">
    <div class="col-4"></div>

    <div class="col-4">
      <button
        id="tyc-confirm-modal"
        class="btn-square-black w-100"
        (click)="close()"
      >
        {{ nameBtn }}
      </button>
    </div>
  </div>
  <div class="modal-footer row d-md-none pb-4 px-5">
    <div class="col-12 mb-3">
      <button
        id="tyc-confirm-modal"
        class="btn-square-black w-100"
        (click)="close()"
      >
        {{ nameBtn }}
      </button>
    </div>
  </div>
</div>
