<div class="modal-body">
  <div class="mb-2 text-center">
    <div class="top-header">{{ "NEW_ORDER.ORDER_DETAIL.MODAL_STOCK_DIGITAL.HEADER" | translate }}</div>
    <div class="sub-header">{{ "NEW_ORDER.ORDER_DETAIL.MODAL_STOCK_DIGITAL.SUB_HEADER" | translate }}</div>
  </div>

  <div class="products-table mb-5 mt-3">
    <div class="table-body-wrapper">
      <div *ngFor="let product of data?.products" class="mb-4 d-flex">
        <img [src]="baseImgURL + product?.image" alt="" class="mr-2">
        <span class="text-left">{{ product?.name }}</span>
      </div>
    </div>
  </div>

  <app-alert
    [type]="'warning'"
    [showMoreContent]="true"
    >
    <div class="d-flex flex-column">
      <span class="warning-wrapper-header">
        {{ "NEW_ORDER.ORDER_DETAIL.MODAL_STOCK_DIGITAL.WARNING_MSG_HEADER" | translate }}
      </span>
      <span class="warning-wrapper"> 
        {{ "NEW_ORDER.ORDER_DETAIL.MODAL_STOCK_DIGITAL.WARNING_MSG" | translate }}
      </span>
    </div>
  </app-alert>
</div>
<div class="modal-footer d-flex justify-content-center flex-column">
  <button class="btn-square-black w-75" (click)="activeModal.dismiss(false)">
    {{ "NEW_ORDER.ORDER_DETAIL.BUTTON.KEEP_BUYING" | translate }}
  </button>
  <button class="btn-square border-0" (click)="activeModal.dismiss(true)">
    {{ continueBtnTranslation | translate }}
  </button>
</div>
