import { createAction, props } from '@ngrx/store';
import { OptionsMet } from '../../models/met/met.model';

export const getOptionsMet = createAction(
  '[Ui MET] getOptionsMet',
  props<{ clientId: number }>(),
);
export const successGetOptionsMet = createAction(
  '[Ui MET] successGetOptionsMet',
  props<{ options: OptionsMet[] }>(),
);
export const failureGetOptionsMet = createAction(
  '[Ui MET] failureGetOptionsMet',
);
export const resetOptionsMet = createAction(
  '[Ui MET] resetOptionsMet',
);
export const saveMetUrl = createAction(
  '[Ui MET] saveUrl',
  props<{ url: string }>(),
);
export const removeMetUrl = createAction(
  '[Ui MET] removeUrl',
);